<template>
  <div class="app">
    <div class="admin_main_block_top">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="商品类型">
            <el-select
                v-model="where.mainType"
                placeholder="请选择"
            >
              <el-option
                  key="10"
                  label="惠豆商城"
                  value="10"
              ></el-option>
              <el-option
                      key="40"
                      label="共享商城"
                      value="40"
              ></el-option>
              <el-option
                  key="20"
                  label="精品馆"
                  value="20"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品分类">
            <el-select v-model="where.category" placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option
                  v-for="(v, k) in classList"
                  :key="k"
                  :label="v.categoryName"
                  :value="v.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input
                v-model="where.keyword"
                placeholder="请输入商品名称"
                clearable
            ></el-input>
          </el-form-item>
          <!--          <el-form-item label="下单时间">-->
          <!--            <el-date-picker-->
          <!--              v-model="date"-->
          <!--              @change="orderDate"-->
          <!--              type="daterange"-->
          <!--              range-separator="~"-->
          <!--              clearable-->
          <!--              value-format="yyyy-MM-dd"-->
          <!--              format="yyyy-MM-dd"-->
          <!--              start-placeholder="开始月份"-->
          <!--              end-placeholder="结束月份"-->
          <!--            >-->
          <!--              <i class="el-icon-date"></i>-->
          <!--            </el-date-picker>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="getGoodsList()"
            >条件筛选
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <div class="admin_main_block_right">
        <el-row :gutter="20">
          <el-col :span="12">
            <router-link to="/Admin/mall/goods/add"
            >
              <el-button type="success" icon="el-icon-circle-plus-outline"
              >添加商品
              </el-button
              >
            </router-link
            >
          </el-col>
          <!--<el-col :span="12">
                        <el-button type="primary" @click="exportData" disabled icon="el-icon-tickets">导出数据</el-button>
                    </el-col>-->
        </el-row>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
          prop="id"
          label="#"
          width="100px"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="商品名称" width="400px">
        <template slot-scope="scope">
          <dl class="table_dl">
            <dt>
              <el-image
                  style="width: 50px; height: 50px"
                  fit="contain"
                  :src="scope.row.picturePath"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i></div
                >
              </el-image>
            </dt>
            <dd class="table_dl_dd_all" :show-overflow-tooltip="true">
              {{ scope.row.name }}
            </dd>
          </dl>
        </template>
      </el-table-column>
      <el-table-column prop="categoryName" label="商品分类"></el-table-column>
      <el-table-column prop="purchasePrice" label="采购价格"></el-table-column>
      <el-table-column prop="salePrice" label="销售价格"></el-table-column>
      <el-table-column prop="beansPrice" label="惠豆价格"></el-table-column>
      <el-table-column
          prop="totalInventory"
          label="总库存数量"
      ></el-table-column>
      <el-table-column prop="totalExchange" label="总兑数量"></el-table-column>
      <el-table-column prop="createDate" label="创建时间"></el-table-column>
      <el-table-column label="操作" fixed="right" width="120px">
        <template slot-scope="scope">
          <el-dropdown>
            <span class="el-dropdown-link">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                    type="text"
                    icon="el-icon-s-shop"
                    @click="showSku(scope.row.id)"
                >查看SKU
                </el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                    type="text"
                    v-if="scope.row.status == 4"
                    icon="el-icon-upload2"
                    @click="operation(scope.row.id, 1)"
                >上架
                </el-button
                >
                <el-button
                    type="text"
                    v-if="scope.row.status == 1"
                    icon="el-icon-upload"
                    @click="operation(scope.row.id, 4)"
                >下架
                </el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                    type="text"
                    icon="el-icon-edit"
                    @click="toEdit(scope.row.id)"
                >修改
                </el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
          @current-change="currentChange"
          @size-change="handleSizeChange"
          background
          :total="totalData"
          :page-size="pageSize"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>

    <el-dialog title="SKU列表" :visible.sync="isShowSku">
      <el-table :data="skuData">
        <el-table-column property="id" label="ID"></el-table-column>
        <el-table-column
            property="name"
            label="商品名称"
            width="260px"
            :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column property="property" label="规格"></el-table-column>
        <el-table-column
            property="exchangeNum"
            label="已兑换数量"
        ></el-table-column>
        <el-table-column
            property="beanPrice"
            label="惠豆价格"
        ></el-table-column>
        <el-table-column
            property="inventoryStock"
            label="商品库存"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    status: {
      type: Number
    },
    classList: {
      type: Array
    },
  },
  data() {
    return {
      list: [],
      class_list: [],
      date: "",
      skuData: "",
      isShowSku: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      row: "",
      selectId: "",
      where: {
        page: 0,
        limit: 0,
      },
    };
  },
  methods: {
    getDate(date) {
      this.where.applyDateStart = date[0];
      this.where.applyDateEnd = date[1];
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    getGoodsList() {
      console.log(this.status);
      console.log(this.classList);
      this.where.page = this.currentPage;
      this.where.limit = this.pageSize;
      this.where.status = this.status;
      this.$post(this.$api.getGoodsList, this.where).then((res) => {
        this.totalData = res.data.count;
        this.list = this.$formatGoods(res.data.data);
      });
    },
    // 删除处理
    del(id) {
      if (this.$isEmpty(id)) {
        return this.$message.error("请先选择删除的对象");
      }
      this.$post(this.$api, {id: id}).then((res) => {
        if (res.code == 200) {
          this.getGoodsList();
          return this.$message.success("删除成功");
        } else {
          return this.$message.error("删除失败");
        }
      });
    },
    showSku(id) {
      this.$post(this.$api.skuList, {id: id}).then((res) => {
        if (res.code == 1) {
          this.isShowSku = true;
          this.skuData = res.data;
        } else {
          return this.$message.error(res.msg);
        }
      });
    },
    currentChange(e) {
      this.currentPage = e;
      this.getGoodsList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getGoodsList();
    },
    exportData() {
      // console.log("exportData");
    },
    toEdit(id) {
      this.$router.push({
        name: "goods_edit",
        query: {
          id: id,
        },
      });
    },
    operation(val, goodsState) {
      this.$postFormData(this.$api.goodsOperation, {
        id: val,
        goodsState: goodsState,
      }).then((res) => {
        if (res.code == 1) {
          this.getGoodsList();
        } else {
          return this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    // this.where.orderStatus = this.orderStatus == -1 ? "" : this.orderStatus;
    this.getGoodsList();
  },
};
</script>
<style lang="scss" scoped>
</style>

