<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>惠豆商城</el-breadcrumb-item>
          <el-breadcrumb-item>商品管理</el-breadcrumb-item>
          <el-breadcrumb-item>产品列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="admin_table_main">
        <template>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="销售中()" name="first">
              <goods-list :status="1" :classList="classList" v-if="firstIs"></goods-list>
            </el-tab-pane>
            <el-tab-pane label="草稿箱()" name="second">
              <goods-list :status="2"  v-if="secondIs"></goods-list>
            </el-tab-pane>
            <el-tab-pane label="已售罄()" name="third">
              <goods-list :status="3"  v-if="thirdIs"></goods-list>
            </el-tab-pane>
            <el-tab-pane label="已下架()" name="forth">
              <goods-list :status="4" v-if="forthIs"></goods-list>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import goodsList from "@/components/admin/mall/goods/list.vue";

export default {
  components: {
    goodsList,
  },
  data() {
    return {
      show: true,
      infoDetail: {},
      shopId: 0,
      query: {},
      date: "",
      classList: [],
      activeName: "first",
      /*定义前一个活动Tab,初始化默认为 ActiveTab */
      oldTab: "add",
      /*定义Tab是否加载-第一个默认加载 */
      firstIs: true,
      secondIs: false,
      forthIs: false,
      fifthIs: false,
      sixthIs: false,
      thirdIs: false,
    };
  },
  methods: {
    handleClick(tab) {
      this[tab.name + "Is"] = true;
      if (tab.name != this.oldTab) {
        this[this.oldTab + "Is"] = false;
        this.oldTab = tab.name;
      }
    },
    getGoodsClass() {
      this.$post(this.$api.goodscategory, {
        page: 0,
        limit: 1000,
        parentId: 0,
      }).then((res) => {
        if (res.code == 1) {
          this.classList = res.data.data;
        }
      });
    },
  },
  created() {
    this.getGoodsClass();
  },
};
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.el-tab-pane {
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.sub-title {
  min-height: 26px;
}

.el-imag {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 178px;
}

.el-imag:hover {
  border-color: #409EFF;
}

.el-icon-picture-outline {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.layadmin-contact-box {
  width: 300px;
  display: inline-block;
  border: 1px solid #e7eaec;
  padding: 40px 0 40px 0;
}

.layadmin-text-center {
  text-align: center;
}

.layadmin-text-center .el-image {
  max-width: 40px;
  border-radius: 50%;
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.el-card {
  text-align: center;
}

.header-form {
  height: 40px;
  padding: 10px 0 10px 0;
}
.admin_main_block_top {
  border: 0px;
}
</style>
